<template>
    <div class="page4">
		<Coldiv direction="left">
            <div style="margin-top: 3.23vh;">
                <Subtitle special="涪" text="昭帮"></Subtitle>
                <div class="fuzhaobangBox">
                    <div class="guanaiRow">
                        <info-card-2 class="guanaiCard" itemName="帮扶时间(天)" :itemValue="fuzhaobang.days"></info-card-2>
                        <info-card-2 class="guanaiCard" itemName="帮扶资金(万元)" :itemValue="fuzhaobang.fund"></info-card-2>
                        <info-card-2 class="guanaiCard" itemName="工作队人数(人)" :itemValue="fuzhaobang.workTeamsMembers"></info-card-2>
                    </div>
                    <div class="guanaiRow">
                        <info-card-2 class="guanaiCard" itemName="帮扶人数(人)" :itemValue="fuzhaobang.helpMembers"></info-card-2>
                        <info-card-2 class="guanaiCard" itemName="提供岗位数(个)" :itemValue="fuzhaobang.postNumber"></info-card-2>
                        <info-card-2 class="guanaiCard" itemName="帮扶项目数(个)" :itemValue="fuzhaobang.projectNumber"></info-card-2>
                    </div>
                </div>
            </div>
            <div style="margin-top: 4.17vh;">
                <Subtitle text="产业振兴"></Subtitle>
				<div class="secondaryTitle">人均可支配收入历年变化</div>
                <div class="promoteBox">
					<bar-chart unit="单位：元" :dataInput="industry" tagName="产业" keyName="3"></bar-chart>
                </div>
				<div class="secondaryTitle">企业风采</div>
				<scrollview class="companyScrollview" :sKey="companies" speed="12">
					<div class="companyInfoBox" v-for="(item, index) in companies" :key="index">
						<img class="companyInfoBg" src="@/assets/page4/Group1.png" alt="">
						<img class="companyIcon" :src="item?item.logoAddress:''" alt="">
						<div class="companyInfo">
							<div class="companyName">{{item.name}}</div>
							<div class="companyTagRow">
								<div class="companyTagOutline" v-for="(tag, jndex) in item.tags" :key="jndex">
									<div class="companyTagInnerline">{{tag}}</div>
								</div>
							</div>
						</div>
					</div>
				</scrollview>
            </div>
			<div style="margin-top: 4.54vh;">
				<Subtitle text="文化振兴"></Subtitle>
				<div class="cultureBox">
					<div class="cultureCard" style="margin-right: 5.26%">
						<img src="@/assets/page4/Group2.png" alt="" style="width: 100%; height: 100%; position: absolute">
						<img class="cultureIcon" :src="culture.length?cultureShow[0].logoAddress:''" alt="">
						<div class="cultureInfo">
							<div class="cultureName">{{culture.length?cultureShow[0].name:''}}</div>
							<div class="cultureTagRow">
								<div class="cultureTagOutline" v-for="(item, index) in culture.length?cultureShow[0].tags:[]" :key="index">
									<div class="cultureTagInnerline">{{item}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cultureCard" v-if="cultureShow.length>=2">
						<img src="@/assets/page4/Group2.png" alt="" style="width: 100%; height: 100%; position: absolute">
						<img class="cultureIcon" :src="culture.length?cultureShow[1].logoAddress:''" alt="">
						<div class="cultureInfo">
							<div class="cultureName">{{culture.length?cultureShow[1].name:''}}</div>
							<div class="cultureTagRow">
								<div class="cultureTagOutline" v-for="(item, index) in culture.length?cultureShow[1].tags:[]" :key="index">
									<div class="cultureTagInnerline">{{item}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Coldiv>
		<Coldiv direction="right">
            <div style="margin-top: 3.23vh">
				<Subtitle text="人才振兴"></Subtitle>
				<div class="laborForceBox">
					<div class="secondaryTitle">就业率</div>
					<div class="progressBox">
						<div class="progressBarOutline">
							<div class="progressBarInner" :style="{width: employRate+'%'}"></div>
							<red-block class="redBlock" :style="{left: employRate+'%'}"></red-block>
						</div>
						<div class="progressValueBox">
							<active-number class="progressValue" :number="employRate"></active-number>
							<div class="progressValue">%</div>
						</div>
					</div>
					<div class="secondaryTitle">教育水平</div>
					<div class="educationRow">
						<yellow-circle class="yellowCircle" itemName="硕士及以上" :itemValue="laborForce.education.master"></yellow-circle>
						<yellow-circle class="yellowCircle" itemName="本科" :itemValue="laborForce.education.undergraduate"></yellow-circle>
						<yellow-circle class="yellowCircle" itemName="专科" :itemValue="laborForce.education.college"></yellow-circle>
						<yellow-circle class="yellowCircle" itemName="义务教育" :itemValue="laborForce.education.primary"></yellow-circle>
					</div>
					<div class="secondaryTitle">人才风采</div>
					<div class="personBox">
						<!--<info-card class="personInfoCard" :dataInput="laborForce.list?laborForce.list[0]:{}" personTag="热情开朗"></info-card>
						<info-card class="personInfoCard" :dataInput="laborForce.list?laborForce.list[1]:{}" personTag="温柔大方"></info-card>-->
						<!-- 使用假数据 -->
						<info-card class="personInfoCard" :dataInput="rencai[0]" personTag="热情开朗"></info-card>
						<info-card class="personInfoCard" :dataInput="rencai[1]" personTag="温柔大方"></info-card>
					</div>
				</div>
			</div>
			<div style="margin-top: 3.7vh">
				<Subtitle text="生态振兴"></Subtitle>
				<div class="secondaryTitle">水位监测</div>
				<div class="lineChartBox">
					<line-chart tagName="水位" :dataInput="waterPosition" unit="m" keyName="1"></line-chart>
				</div>
				<div class="secondaryTitle">水质监测</div>
				<div class="lineChartBox">
					<line-chart tagName="水质" :dataInput="waterQuality" unit="" keyName="2"></line-chart>
				</div>
			</div>
		</Coldiv>
	</div>
</template>

<script>
const Coldiv = () => import('./components/coldiv.vue');
const Subtitle = () => import('./components/subtitle.vue');
const infoCard2 = () => import('./components/infoCard2.vue');
const yellowCircle = () => import('./components/yellowCircle.vue');
const infoCard = () => import('./components/infoCard3.vue');
const lineChart = () => import('./components/lineChart.vue');
const barChart = () => import('./components/barChart.vue');
const redBlock = () => import('./components/redBlock.vue');
const activeNumber = () => import('./components/activeNumber.vue');
const scrollview = () => import('./components/scrollview.vue');

export default {
    name: 'page4',
    components: {
        Coldiv,
        Subtitle,
        infoCard2,
		yellowCircle,
		infoCard,
		lineChart,
		barChart,
		redBlock,
		activeNumber,
		scrollview
    },
    data() {
        return {
			barChartEl: null,
			barChartFontSize: 16,
			laborForce:{
				education: {
					master: 0,
					undergraduate: 0,
					college: 0,
					primary: 0
				}
			},
			employRate: 0,
			//水位历史数据和水质历史数据都是假数据，放在data里。
			//这2个数据若需要新增API从后端获取的话，在requests.js中配置好相应的接口调用函数后，在当前页的methods中获取并处理
			waterPosition:[
				{
					date: '2023/07',
					value: 6
				},
				{
					date: '2023/08',
					value: 10.4
				},
				{
					date: '2023/09',
					value: 11.2
				},
				{
					date: '2023/10',
					value: 7.9
				},
				{
					date: '2023/11',
					value: 7.8
				},
				{
					date: '2023/12',
					value: 10.2
				},
				{
					date: '2024/01',
					value: 5.5
				}
			],
			waterQuality:[
				{
					date: '2023/07',
					value: 345
				},
				{
					date: '2023/08',
					value: 221
				},
				{
					date: '2023/09',
					value: 256
				},
				{
					date: '2023/10',
					value: 310
				},
				{
					date: '2023/11',
					value: 162
				},
				{
					date: '2023/12',
					value: 280
				},
				{
					date: '2024/01',
					value: 208
				}
			],
			industry: [
				/* {
					name: '2018年',
					value: 32400
				},
				{
					name: '2019年',
					value: 34600
				},
				{
					name: '2020年',
					value: 35700
				},
				{
					name: '2021年',
					value: 39100
				},
				{
					name: '2022年',
					value: 42600
				},
				{
					name: '2023年',
					value: 46230
				} */
			],
			//人才风采
			rencai: [{
				name: '罗红汪库',
				picture: {
					url: 'https://zmsq.domidodo.cn/dev-api/profile/upload/2023/12/18/WPS图片(1)_20231218113903A046.jpeg'
				}
			},{
				name: '马海中华',
				picture: {
					url: 'https://zmsq.domidodo.cn/dev-api/profile/upload/2023/12/18/WPS图片(1)_20231218104713A016.jpeg'
				}
			}],
			fuzhaobang: {
				days: 0,
				fund: 0,
				workTeamsMembers: 0,
				helpMembers: 0,
				postNumber: 0,
				projectNumber: 0
			},
			companies: [],
			culture:[],
			cultureShow:[],
			showCultureIndex: 0,
			cultureSIV: null,
			pollingSIV: null,
        }
    },
    mounted() {
		this.$eventBus.$emit('switchPage', 4);
		this.updatePageData();
		this.pollingSIV = setInterval(() => {
			this.updatePageData();
		}, this.$c.pollingInterval)
    },
    methods:{
		//初始化页面
		updatePageData(){
			this.getCompanyList();
			this.getCultureList();
			this.getTalentList();
			//已在获取人才列表的函数中调用getEmployRate，不用再在初始化时去调用它
			//this.getEmployRate();
			this.getFuzhaobang();
			this.getIncomeData();
		},
		//获取涪昭帮信息
		getFuzhaobang(){
			this.$api.getFuzhaobang().then(res => {
				let d = res.data.data;
				this.fuzhaobang = d;
			}).catch(err => {
				console.log(err);
			})
		},
		//获取数据并计算就业率
		getEmployRate(res){
			let list = res.data.rows;
			let total = res.data.total;
			let employed = 0;
			for(let i = 0;i < list.length;i++){
				let item = list[i];
				if(item.employmentSituation != '未就业'){
					//即使是学生和退休人员都应算为已就业人员
					employed++;
				}
			}
			this.employRate = parseInt(employed*100/total);
		},
		//获取人才信息，并分类
		getTalentList(){
			let sl = ['文盲','小学','初中','义务教育','中专','高中','电大','大专','专科','本科','硕士','硕士及以上','博士'];

			//下面被注释的代码表示从已有的人才列表中获取数据的方法，目前不需要

			/*let that = this;
			let taskList = [];
			for(let i = 0;i < sl.length;i++){
				taskList.push(new Promise((resolve, reject) => {
					that.$api.getTalentList({
						educationalLevel: sl[i],
						pageSize: 9999999
					}).then(res => {
						let obj = {
							list: res.data.rows,
							total: res.data.total,
							typeName: sl[i]
						}
						resolve(obj);
					}).catch(err => {
						reject(err);
					})
				}))
			}
			let pm = Promise.all(taskList); */

			//暂时从居民列表中获取所有居民的数据，并统计所有居民的学历情况
			let pm = this.$api.loadClassifyList({
				pageSize: 99999999
			})
			pm.then(res => {
				//处理就业率的函数也需要居民列表，就把居民列表的数据给两个函数共享了
				this.getEmployRate(res);
				let d = res.data.rows;
				let arr = [];
				for(let i = 0;i < sl.length;i++){
					arr.push({
						list: [],
						total: 0,
						typeName: sl[i]
					})
				}
				for(let j = 0;j < d.length;j++){
					let edu = d[j].education;
					let x = sl.indexOf(edu);
					if(x >= 0){
						arr[x].total++;
						arr[x].list.push(d[j])
					}
				}
				let o = {
					education: {
						master: arr[12].total + arr[11].total + arr[10].total,
						undergraduate: arr[9].total,
						college: arr[8].total + arr[7].total + arr[6].total,
						primary: arr[5].total + arr[4].total + arr[3].total + arr[2].total + arr[1].total + arr[0].total
					},
					list: []
				}
				//反向遍历所有类型的人才列表，将其合并后，取其前两项到页面展示（靠前项为高学历人才）
				//在当前的template模板中，不展示这两项内容，而是按社区要求展示了两位社区人员的信息（在data里）。若要修改，到template中修改即可
				let l = [];
				for(let j = sl.length-1;j >= 0;j--){
					l = l.concat(arr[j].list);
				}
				o.list = l;
				this.laborForce = o;

			}).catch(err => {
				console.log(err);
			})
		},
		//获取近年人均收入情况
		getIncomeData(){
			this.$api.getIncome().then(res => {
				let data = res.data.rows;
				let industry = [];
				let a = 0;
				//如果数据中的年份数太多，则最多只取最近6年展示。少于等于6条则全部展示。
				if(data.length > 6){
					a = data.length - 6;
				}
				for(let i = a;i < data.length;i++){
					let o = {
						name: data[i].year + '年',
						value: data[i].revenue
					}
					industry.push(o);
				}
				this.industry = industry;
			})
		},
		//获取企业信息
		getCompanyList(){
			this.$api.getCompanies().then(res => {
				let d = res.data.rows;
				let o = [];
				for(let i = 0;i < d.length;i++){
					let obj = {
						name: d[i].enterpriseName,
					}
					let picture = JSON.parse(d[i].picture);
					let tags = [];
					obj.logoAddress = picture.url;
					let bs = d[i].mainBusiness;
					if(typeof(bs) == 'object' && bs.constructor == Array){
						for(let j = 0;j < bs.length;j++){
							tags.push(bs[j]);
						}
					}else{
						tags.push(bs);
					}
					obj.tags = tags;
					o.push(obj);
				}
				this.companies = o;
			})
		},
		//获取文创列表
		getCultureList(){
			this.$api.getCultureList({
				pageSize: 99999
			}).then(res => {
				let d = res.data.rows;
				let lt = [];
				for(let i = 0;i < d.length;i++){
					let obj = {
						name: d[i].brandName,
						logoAddress: JSON.parse(d[i].picture).url,
						tags:['文创']
					}
					lt.push(obj);
				}
				this.culture = lt;
				this.showCultureIndex = -2;
				this.setCultureList();
				this.cultureSIV = setInterval(()=>{
					this.setCultureList();
				}, 10000)
			})
		},
		//设置文创列表的方法，在getCultureList中被调用
		setCultureList(){
			let list = this.culture;
			let dl = list.length;
			let showList = [];
			let i = this.showCultureIndex;
			if(i+2 >= dl){
				i = 0;
				this.showCultureIndex = 0;
			}else{
				i += 2;
				this.showCultureIndex = i;
			}
			showList.push(list[i]);
			if(dl-i > 1){
				showList.push(list[i+1]);
			}
			this.cultureShow = showList;
		}
    },
    beforeDestroy(){
		clearInterval(this.cultureSIV);
		clearInterval(this.pollingSIV);
    }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/index.less";
.page4 {
	height: 100%;
    width: 100%;
    background: none;
    overflow: hidden;
	display: flex;
	justify-content: space-between;
	transform: translateY(-1.11vh);
	z-index: 10;

    .fuzhaobangBox {
        margin-top: 1.85vh;
        width: 93.57%;
        height: 11.85vh;
        margin-left: 3.1%;

        .guanaiRow {
            width: 100%;
            height: 43.75%;
            margin-bottom: 1.48vh;
            display: flex;
            justify-content: space-between;

            .guanaiCard {
                width: 31.29%;
            }
        }
    }

    .promoteBox {
        width: 90%;
        height: 18.43vh;
        margin-left: 4.76%;
		margin-top: 0.37vh;
        
    }

	.secondaryTitle {
		margin-top: 1.85vh;
		height: 2.78vh;
		font-family: 'titleFont',sans-serif;
		font-size: 1.85vh;
		line-height: 2.78vh;
		color: #fff;
		margin-left: 4.76%;
		display: flex;
		align-items: center;
	}
	.companyScrollview {
		width: 90.48%;
		height: 16.3vh;
		margin-left: 4.76%;
		
		.companyInfoBox {
			width: 100%;
			height: 7.41vh;
			position: relative;
			display: flex;
			align-items: center;
			margin-bottom: 1.48vh;

			.companyInfoBg {
				position: absolute;
				width: 100%;
				height: 100%;
			}
			.companyIcon {
				height: 70%;
				aspect-ratio: 1/1;
				border-radius: 4px;
				margin-left: 4.21%;
			}
			.companyInfo {
				margin-left: 4.21%;
				height: 70%;
				width: 100%;

				.companyName {
					height: 2.04vh;
					font-size: 1.48vh;
					line-height: 2.04vh;
					color: #fff;
					font-family: 'pingfangM', sans-serif;
					font-weight: 500;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				
				.companyTagRow {
					height: 2.22vh;
					margin-top: 0.74vh;
					width: 100%;
					display: flex;

					.companyTagOutline {
						width: 24.66%;
						height: 2.22vh;
						border: #ffd03b 1px solid;
						border-radius: 1.11vh;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 2.74%;


						.companyTagInnerline {
							width: 94.44%;
							height: 1.85vh;
							display: flex;
							align-items: center;
							justify-content: center;
							border: #ff3b3b 1px solid;
							border-radius: 0.93vh;
							text-align: center;
							font-size: 1.3vh;
							line-height: 1.85vh;
							color: #fff;
							font-family: 'pingfangSc', sans-serif;
							font-weight: 400;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
	}
	
	.cultureBox {
		width: 90.48%;
		height: 7.41vh;
		margin-top: 1.85vh;
		margin-left: 4.76%;
		display: flex;

		.cultureCard {
			width: 47.37%;
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;
		}
		.cultureIcon {
			height: 70%;
			aspect-ratio: 1/1;
			border-radius: 4px;
			margin-left: 8.89%;
		}
		.cultureInfo {
			margin-left: 6.66%;
			height: 70%;
			width: 100%;

			.cultureName {
				height: 2.04vh;
				font-size: 1.48vh;
				line-height: 2.04vh;
				color: #fff;
				font-family: 'pingfangM', sans-serif;
				font-weight: 500;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			
			.cultureTagRow {
				height: 2.22vh;
				margin-top: 0.74vh;
				width: 100%;
				display: flex;

				.cultureTagOutline {
					width: 45.83%;
					height: 2.22vh;
					border: #ffd03b 1px solid;
					border-radius: 1.11vh;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 2.74%;


					.cultureTagInnerline {
						width: 94%;
						height: 1.85vh;
						display: flex;
						justify-content: center;
						align-items: center;
						border: #ff3b3b 1px solid;
						border-radius: 0.93vh;
						text-align: center;
						font-size: 1.3vh;
						line-height: 1.85vh;
						color: #fff;
						font-family: 'pingfangSc', sans-serif;
						font-weight: 400;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}

	.progressBox {
		width: 90.48%;
		margin-left: 5.24%;
		height: 2.13vh;
		margin-top: 0.46vh;
		display: flex;
		align-items: center;
		position: relative;


		.progressBarOutline {
			height: 86.96%;
			width: 84.47%;
			border: 1px #ffd03b solid;
			border-radius: 0.93vh;
			background: rgba(243,184,5,0.2);
			display: flex;
			align-items: center;
			position: relative;

			.progressBarInner {
				height: 90%;
				margin-left: 0.09vh;
				background: #ffd03b;
				border-radius: 0.74vh;
				transition: width 1s;
			}
			.redBlock {
				height: 100%;
				border: #ff3b3b 1px solid;
				margin-left: -1.065vh;
				transition: left 1s;
			}
		}
		.progressValueBox {
			position: absolute;
			right: 0;
			display: flex;
			justify-content: flex-end;
			
			.progressValue {
				height: 2.13vh;
				font-size: 1.85vh;
				line-height: 2.13vh;
				color: #fff;
				font-family: 'titleFont', sans-serif;
			}
		}
		
	}
    .educationRow {
        width: 97.14%;
        margin-left: 1.43%;
        height: 8.24vh;
        margin-top: 0.74vh;
        display: flex;
        .yellowCircle {
            width: 25%;
        }
    }

	.personBox {
		width: 90.48%;
		height: 5.56vh;
		margin-left: 4.76%;
		margin-top: 0.74vh;
		display: flex;
		justify-content: space-between;

		.personInfoCard {
			width: 47.37%;
		}
	}

	.lineChartBox {
		width: 94%;
		height: 17.31vh;
		margin-left: 4.76%;
	}
}

</style>
